<template>
  <div>
    <b-modal
      ref="modal-avisos"
      size="lg"
      hide-footer
      hide-header
      title="Avisos"
    >
      <div class="d-block">
        <img
          class="logocongresomodal"
          src="../assets/logos/XVI-logo-anio2-gris-2020.svg"
          alt=""
        />
        <h5 class="titulo-modal">Avisos</h5>
        <div class="container container-avisos">
          <div
            class="aviso shadow-sm"
            v-for="a in avisos"
            :key="a.key"
            v-html="a.aviso"
          ></div>
        </div>
      </div>
    </b-modal>
    <div class="container-fluid container-marco-inicio">
      <div class="container-bienvenida">
        <img class="ilustra-inicio" src="../assets/vector_principal.svg" alt="">
        <h1 class="titulo-micrositio">Micrositio de Combate a la Corrupción y Participación Ciudadana.</h1>
        <p>Como legisladores es nuestro deber informar a la ciudadanía sobre el proceso de implementación del Sistema Anticorrupción en Quintana Roo, aplicando el principio de máxima publicidad. Hemos pugnado por un cambio de fondo y por esta razón, sentamos las bases para una transformación gradual pero verdadera, desarrollando políticas que avancen en dirección correcta para enmendar el modelo pasado.</p>
      </div>
    </div>
    <PubHomeIntegrantesComision />
    <PubHomeSecciones />
  </div>
</template>

<script>
import firebase from "../firebase";
//import router from "../router";
import PubHomeIntegrantesComision from "../components/PubHomeIntegrantesComision.vue";
import PubHomeSecciones from "../components/PubHomeSecciones.vue"

export default {
  name: "Home",
  components: {
    PubHomeIntegrantesComision,
    PubHomeSecciones
  },
  data() {
    return {
      show: false,
      refAvisos: firebase.firestore().collection("avisos"),
      avisos: {},
      modal: false,
    };
  },
  created() {
    this.refAvisos.where("activo", "==", true).onSnapshot((querySnapshot) => {
      this.avisos = [];
      querySnapshot.forEach((doc) => {
        this.avisos.push({
          key: doc.id,
          aviso: doc.data().aviso,
        });
      });

      if (this.avisos.length != 0) {
        this.showModal();
      }
    });
  },
  methods: {
    showModal() {
      this.$refs["modal-avisos"].show();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/abstracts/variables";

.container-marco-inicio {
  padding: 6rem 1rem 0.5rem;
  background-image: url(../assets/fondo.svg);
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000;
  @include media-breakpoint-up(md) {
    padding: 6rem 1rem 2rem;
  }

  .container-bienvenida {
    width: 280px;
    margin: 0 auto;
    text-align: center !important;
    @include media-breakpoint-up(md) {
      width: 700px;
    }

    .ilustra-inicio {
      width: 50%;
      margin-bottom: 1rem;
      @include media-breakpoint-up(md) {
        width: 350px;
        margin-bottom: 2rem;
      }
    }
    .titulo-micrositio {
      font-size: 1rem;
      font-weight: 700;
      margin-bottom: 1rem;
      @include media-breakpoint-up(md) {
        font-size: 1.75rem;
      }
    }
    p {
      font-size: 0.7rem;
      font-weight: 300;
      @include media-breakpoint-up(md) {
        font-size: 0.85rem;
      }
    }
  }
}

</style>
