<template>
  <div class="container container-integrantes-comision">
    <h1 class="titulo-integrantes">Comisión Anticorrupción, Participación Ciudadana y Órganos Autónimos.</h1>
    <ul class="lista-integrantes">
      <li v-for="i in items" :key="i.key">
        <a :href="i.url" target="_blank">
          <img class="img-integrante" :src="i.foto" :alt="i.nomobre">
        </a>
        <p class="nombre">Dip. {{ i. nombre }}</p>
        <p class="apellidos">{{ i.apellidos }}</p>
        <p class="cargo">{{ i.cargo }}</p>
      </li>
    </ul>
  </div>
  
</template>

<script>
  import firebase from "../firebase";

  export default {
    name: "PubHomeIntegrantesComision",
    data() {
      return {
        ref: firebase.firestore().collection("secciones").doc("uc43QE6JkRDmxJua90Qu").collection("subsecciones").doc("WWZJM2nOXmmMeHcYz7Qn").collection("publicaciones").doc("foUH7y5UPDIFMRez4XrV").collection("fichas"),
        items: {},
      };
    },
    created() {
      this.ref.orderBy("orden").onSnapshot((querySnapshot) => {
        this.items = [];
        querySnapshot.forEach((doc) => {
          this.items.push({
            key: doc.id,
            ...doc.data(),
          });
        });
        //console.log(this.items)
      });
    },
  }
</script>
