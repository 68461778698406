import firebase from "firebase/app";

import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const settings = { timestampsInSnapshots: true };

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDQbwds7fRyn1clffv790J1jJDT3XHRneo",
  authDomain: "anticorrupcion-podlegqroo.firebaseapp.com",
  projectId: "anticorrupcion-podlegqroo",
  storageBucket: "anticorrupcion-podlegqroo.appspot.com",
  messagingSenderId: "992073653417",
  appId: "1:992073653417:web:316456a3bca9a1aae81db8",
  measurementId: "G-3CBXNDH9K0"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings(settings);

export default firebase;